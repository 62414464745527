import React, { useContext ,useEffect,useState,useRef} from 'react';
import { useHistory } from 'react-router-dom';
import { CollectionContext, } from '../../contexts/CollectionContext';
import { caseObj, selectCases } from '../../lib/selectCases';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import './CollectionFieldsWithOptions.styles.scss';
import { saveData,getData,removeFromIndexedDB } from '../../dataFiles/indexedDB';
import CircularProgressIndicator from '../../components/CircularProgress/CircularProgress.component';
import { BorderColor, CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Modal from "../../components/Modal/Modal.component";
import CustomButton from "../../components/CustomButton/CustomButton.component";

import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import CheckChange from '../../images/check-square 1.svg'
import CheckSquare from '../../images/check-square.svg'
import CheckCross from '../../images/check-cross.svg'
import CheckEmpty from '../../images/check-empty.svg'
import { useParams } from 'react-router-dom';
import UpArrow from '../../images/chevron-up 1.svg'
import BottomPopup from '../BottomPopup/BottomPopup.component';
import { useJobHandler } from '../../customHooks/jobHandlerHook';
import SearchBar from '../SearchBar/SearchBar.component';
const CollectionFieldsWithOptions = ({selectAllChecked,byButtonClick, type}) => {
  const {  collectionData, updateCollectionData, disableAutoOptimizeField, fetchCollections,submitChange,
    isBtnDisabled,
    btnText,
    showOptimizeBtn,message  } = useContext(CollectionContext);
    const { setJobIds, jobsInfo, invalidIds, failedJobsArr, totalJobs } = useJobHandler();
  let { api, siteId } = useParams();
  const [ids, setIds] = useState([]);
  const [filteredCollectionData, setFilteredCollectionData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [show, setShow] = useState({});
  const [tickedCheckboxes, setTickedCheckboxes] = useState([]);
  const [totalFields, setTotalFields] = useState(0);
  const [totalFieldsInCollection,settotalFieldsInCollection] = useState(0);
  const [showPricingBtn, setShowPricingBtn] = useState(false);
  const [showOptimizedJobs, setShowOptimizedJobs] = useState(false);
  const [errState, setErrState] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [optimizedStatus, setOptimizedStatus] = useState(false);
  const [optimizedJobs, setOptimizedJobs] = useState([]);
  const [optimized, setOptimized] = useState(0);
  const [results, setResults] = useState({ originalImagesSize: 0, optimizedImagesSize: 0, totalImagesOptimized: 0 });
  const [showProgress, setShowProgress] = useState(false);
  const [optimizationInProgress, setOptimizationInProgress] = useState(false);
  const [fieldsId,setFieldsId] = useState([]);
  const [jobsInLs,setJobsInLs] = useState(false)
  const [selectedRichText,setSelectedRichText] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const progress = 100 / totalFields * optimized
  const history = useHistory();
  useEffect(() => {
    const totalCount = filteredCollectionData.reduce(
      (count, collection) => count + collection.collectionFields.length,
      0
    );
    settotalFieldsInCollection(totalCount);
  }, [filteredCollectionData]);
  const closeOpenModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };
  const handleOptimizeClick = async (richTextCheck) => {
    // setIsBtnDisabled(true);
    // setBtnText('Please Wait..');
    if((richTextCheck === false && selectedRichText <= 0) || richTextCheck === true){
    let conversion;
    if(type === 'convert'){
      conversion = true;
    }else{
      conversion = false;
    }
    const jobsArray = await submitChange(false,conversion);
    console.log("jobsArray: ",jobsArray);
    if (jobsArray) {
      setOptimizedJobs(jobsArray);
      setShowOptimizedJobs(true);
      setOptimizationInProgress(true);
      setShowProgress(true);
      setJobIds(jobsArray);
      setIds(jobsArray);
      const optimizationEvent = new Event('optimizationInProgress');
      document.dispatchEvent(optimizationEvent);
      localStorage.setItem('optimizationStatus',true)
      localStorage.setItem('jobId', JSON.stringify(jobsArray));
      localStorage.setItem('totalFields', tickedCheckboxes.length);
    } else {
      // setBtnText('Error');
      console.log('enter');
      setShowPricingBtn(true);
      // setShowOptimizeBtn(false);
    }    
    }else{
      closeOpenModal()
    }
  };
  useEffect(() => {
    const fetchData = async () => {
        const savedJobIds = localStorage.getItem('jobId');
        const parsedJobIds = JSON.parse(savedJobIds);
        if (parsedJobIds && parsedJobIds.length > 0) {
            setJobsInLs(true);
            setOptimizedJobs(parsedJobIds);
            setShowOptimizedJobs(true);
            setOptimizationInProgress(true);
            setShowProgress(true);
            setJobIds(parsedJobIds);
            setIds(parsedJobIds);
            const optimizationEvent = new Event('optimizationInProgress');
            document.dispatchEvent(optimizationEvent);
        }

        const localStorageData = await getData('collectionDataState');
        console.log(localStorageData);
        if (localStorageData) {
            const parsedData = JSON.parse(localStorageData);
            if(parsedData.collectionData.length > 0){
              updateCollectionData(parsedData.collectionData);
              console.log(parsedData.tickedCheckboxes)
              setTickedCheckboxes(parsedData.tickedCheckboxes);
            }
        }
    };

    fetchData(); // Call the async function

}, []);
  
    // Get collection length and set it.
    useEffect(() => {
      if (totalFields > 0) {
      }
    }, [totalFields])
  
    const MultiOptionBox = ({ toggleModal }) => {
      return (
        <div className="box-outline" id="modal-div">
          <div className="inner-box">
            <div onClick={toggleModal} className="modal-close">
              <CloseSharpIcon fontSize="large" style={{ color: "#FACE48" }} />
            </div>
    
            <h3 className="title">Warning</h3>
            <p className="desc">
            <center>
            You have selected a <strong>"Rich Text" Element</strong> within your CMS. At present, Webflow’s API removes embed code elements if we update them via the API. <strong>If you optimize the selected “Rich text” field, all the embeds inside the said “Rich Text” will be removed.</strong></center>
            </p>
            <div className="btn-box">
              <CustomButton onClick={handleClickRichText} black={true}>Continue</CustomButton>
            </div>
          </div>
        </div>
      );
    };

    
  const handleClick = () => {
    handleOptimizeClick(false);
  };
  const handleClickRichText = () => {
    closeOpenModal()
    handleOptimizeClick(true)
  }  

    useEffect(() => {
      setOptimized(0);
      setResults({ originalImagesSize: 0, optimizedImagesSize: 0, totalImagesOptimized: 0 });
      checkAndUpdateOptimizedValues();
    }, [jobsInfo]);
    useEffect(() => {
      const updateData = async () => {
        if (!optimizationInProgress) {
          let updatedCollectionData = collectionData;
    
          if (selectAllChecked === true || (selectAllChecked === false && byButtonClick === true)) {
            updatedCollectionData = collectionData.map(collection => ({
              ...collection,
              collectionFields: collection.collectionFields.map(field => {
                if (field.type === 'RichText') {
                  if (selectAllChecked) {
                    setSelectedRichText(prvSelected => prvSelected + 1);
                  } else {
                    setSelectedRichText(prvSelected => prvSelected - 1);
                  }
                }
                field['isChecked'] = selectAllChecked;
                return field;
              }),
            }));
    
            updateCollectionData(updatedCollectionData);
    
            const updatedLocalStorageData = { collectionData: updatedCollectionData, tickedCheckboxes };
            await saveData('collectionDataState', JSON.stringify(updatedLocalStorageData));
          }
          console.log(selectAllChecked);
          console.log(byButtonClick);
          if (selectAllChecked) {
            const allFieldIds = updatedCollectionData.flatMap(collection =>
              collection.collectionFields.map(field => field.id)
            );
            setTickedCheckboxes(allFieldIds);
          } else if (selectAllChecked === false && byButtonClick === true) {
            setTickedCheckboxes([]);
            await removeFromIndexedDB("collectionDataState");
          }
        }
      };
    
      updateData();
    }, [selectAllChecked, byButtonClick]);
    
    useEffect(() => {
      const totalFieldsInLS = parseInt(localStorage.getItem('totalFields'));
      if (totalFields > 0 && totalFields === totalFieldsInLS && optimized === totalFields) {
        setOptimizedStatus(true)
      }
      if (optimized > 0 && !showResults) {
        setShowResults(true)
      }
      const token = window.localStorage.getItem("token");
      // if (token) onLoadUser(token)
  
      if (totalJobs > 0 && (totalJobs === failedJobsArr.length)) {
        setShowResults(true)
      }
    }, [optimized, totalFields, totalJobs, failedJobsArr])
  
    //handle if there are invalid job ids
    useEffect(() => {
      if (invalidIds.length) {
        const doIdsExist = invalidIds.filter(id => !ids.includes(id));
        if (!doIdsExist.length) {
          setErrState(true);
        }
      }
    }, [invalidIds])
  
    useEffect(() => {
      if (errState) {
        setOptimized(0);
        setOptimizedStatus(false);
      }
    }, [errState])
  
  
    const isBtnActive = optimizedStatus ? false : true;
  
    // const addApiKeySite = () => {
    //   history.push(`/pixie/add-api-key`);
    // }
    const checkAndUpdateOptimizedValues = () => {
      let hasTotalFields = 0;
      let optimizedNumOfFields = 0;
      const updatedFieldsId = [];
      Object.keys(jobsInfo).forEach(col => {
        jobsInfo[col].forEach(({collectionFieldData, success, successData }) => {
          for (const collectionField of collectionFieldData) {
            if(!updatedFieldsId.includes(collectionField.id)){
              updatedFieldsId.push(collectionField.id)
            }
          }
          hasTotalFields = hasTotalFields + collectionFieldData.length;
          if (success) {
            optimizedNumOfFields = optimizedNumOfFields + collectionFieldData.length;
            // const { optimizedImagesSize, originalImagesSize, totalImagesOptimized } = successData.optimizationResults;
            const summedResults = successData.optimizationResults.reduce((accumulator, currentItem) => {
              accumulator.originalImagesSize += currentItem.originalImagesSize;
              accumulator.optimizedImagesSize += currentItem.optimizedImagesSize;
              accumulator.totalImagesOptimized += currentItem.totalImagesOptimized;
              return accumulator;
            }, {
              originalImagesSize: 0,
              optimizedImagesSize: 0,
              totalImagesOptimized: 0
            });

            setResults(prevResults => {
              return {
                originalImagesSize: prevResults.originalImagesSize += summedResults.originalImagesSize,
                optimizedImagesSize: prevResults.optimizedImagesSize += summedResults.optimizedImagesSize,
                totalImagesOptimized: prevResults.totalImagesOptimized += summedResults.totalImagesOptimized,
              }
            })
          }
        })
      })
      setFieldsId(updatedFieldsId)
      setTotalFields(hasTotalFields);
      setOptimized(optimizedNumOfFields);
    }
    const getMetaForCollection = (successData) => {
      let ci = 0;
      let si = 0;
      if (successData && successData.optimizationResults) {
        if (successData.optimizationResults.compressedImageURL instanceof Array) {
          ci = successData.optimizationResults.compressedImageURL.length;
        }
        if (successData.optimizationResults.skippedImages instanceof Array) {
          si = successData.optimizationResults.skippedImages.length;
        }
        return `Compressed Images: ${ci} | Skipped Images: ${si}`;
      } else return 'N/A';
    }
  
    const IconByStatus = ( status,field,process) => {
      if (status === 'completed') {
        return <img className='check-box-btn' src={CheckSquare} alt="CheckSquare icon" style={{ marginLeft: '0.65vw', marginRight: '0.32vw', marginTop: '0.13vh' }} />;
      } else if (status === 'failed') {
        return <img className='check-box-btn' src={CheckCross} alt="CheckCross icon" style={{ marginLeft: '0.65vw', marginRight: '0.32vw', marginTop: '0.13vh' }} />;
      } else if (status === 'running') {
        return <CircularProgressIndicator value={process} className='check-box-btn' size="30px" style={{ color: 'black', marginLeft: '0.65vw',marginRight: '0.32vw' }} />;
      }      
       else {
          return <Checkbox id={field.id} icon={<img className='check-box-btn' src={CheckEmpty} alt="Check icon" />}
              checked={field.isChecked} onChange={handleCheckBoxChange}
               checkedIcon={<img className='check-box-btn'src={CheckChange} alt="Check icon" />}
              name="field.name"/>      }
    };
  
  const updateFieldsDataInArr = (arrayData, fieldId, optionValue) => {
    if (!arrayData) return;
    return arrayData.map(ele => {
      ele.collectionFields = ele.collectionFields.map(fieldData => {
        if (fieldData.id === fieldId) {
          const { sizes, resizeStrategy } = selectCases(optionValue);
          fieldData.imageSizeToResize = sizes;
          fieldData.resizeStrategy = resizeStrategy;
          fieldData.selectValue = optionValue;
        }
        return fieldData;
      });
      return ele;
    });
  };

  useEffect(() => {
    const filteredData = collectionData.map(collection => ({
      ...collection,
      collectionFields: collection.collectionFields.filter(field => (
        collection.collectionDetails.displayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        field.displayName.toLowerCase().includes(searchQuery.toLowerCase())
      )),
    }));
  
    setFilteredCollectionData(filteredData);
  }, [searchQuery, collectionData])
    
  const handleSearch = event => {
    setSearchQuery(event.target.value);
  };
  const updateCheckBoxFieldDataInArr = (arrData, fieldId, key, value) => {
    return arrData.map(ele => {
      ele.collectionFields = ele.collectionFields.map(fieldData => {
        if (fieldData.id === fieldId) {
          if(fieldData.type ==="RichText"){
            if(value === true){
              setSelectedRichText((prvSelected) => prvSelected + 1)
            }else if(value === false){
              setSelectedRichText((prvSelected) => prvSelected - 1)
            }
          }
          fieldData[key] = value;
        }
        return fieldData;
      });
      return ele;
    });
  };

  const handleOptionChange = (event) => {
    const { value, selectedOptions } = event.target;
    const { field } = selectedOptions[0].dataset;
    const newArr = updateFieldsDataInArr(collectionData, field, value);
    updateCollectionData(newArr);
  };

  const handleCheckBoxChange = async event => {
    if(!optimizationInProgress){
      const { id } = event.target;
      const isChecked = event.target.checked;
      const newArr = await updateCheckBoxFieldDataInArr(collectionData, id, 'isChecked', isChecked);
      updateCollectionData();
      console.log(isChecked)
      if (isChecked) {
        setTickedCheckboxes(prevTicked => [...prevTicked, id]);
        console.log(tickedCheckboxes)
      } else {
        setTickedCheckboxes(prevTicked => prevTicked.filter(item => item !== id));
        console.log(tickedCheckboxes)
      }
    const allCheckboxesSelected = await collectionData.every((collection) =>
      collection.collectionFields.every((field) => field.isChecked)
    );
    const updatedLocalStorageData = { collectionData, tickedCheckboxes };
    const checkboxChangeEvent = new CustomEvent('checkboxChange', { detail: allCheckboxesSelected });
    document.dispatchEvent(checkboxChangeEvent);
    await saveData('collectionDataState', JSON.stringify(updatedLocalStorageData));
    }
  };
  useEffect(() => {
    (async () => {
      const updatedLocalStorageData = { collectionData, tickedCheckboxes };
      await saveData('collectionDataState', JSON.stringify(updatedLocalStorageData));
    })();
  }, [tickedCheckboxes]);

  const disableAutoOptimization = async id => {
    await disableAutoOptimizeField(id);
    fetchCollections({ api, siteId });
  }

  const handleAutoOptimizeCheckBoxChange = event => {
    const { id } = event.target;
    const isAutoOptimize = event.target.checked;
    const newArr = updateCheckBoxFieldDataInArr(collectionData, id, 'isAutoOptimize',
      isAutoOptimize);
    updateCollectionData(newArr);
  };
const handleCollectionCheckboxChange = async (collectionId, isChecked) => {
  const updatedCollectionData = collectionData.map(collection => {
    if(!optimizationInProgress){
      if (collection.collectionDetails.id === collectionId) {
        collection.collectionFields = collection.collectionFields.map(field => {
          if (field.type === 'RichText') {
            if(isChecked === true){
              setSelectedRichText(prvSelected => prvSelected + 1);
            }else if(isChecked === false){
              setSelectedRichText(prvSelected => prvSelected - 1);
            }
          }
          return {
            ...field,
            isChecked: isChecked
          };
        });
      }
    }
    return collection;
  });
  updateCollectionData(updatedCollectionData);
  if (isChecked) {
    const collectionFieldsIds = collectionData
      .find(collection => collection.collectionDetails.id === collectionId)
      .collectionFields.map(field => field.id);

    const updatedTickedCheckboxes = [...new Set([...tickedCheckboxes, ...collectionFieldsIds])];
    setTickedCheckboxes(updatedTickedCheckboxes);
  } else {
    const collectionFieldsIds = collectionData
      .find(collection => collection.collectionDetails.id === collectionId)
      .collectionFields.map(field => field.id);

    const updatedTickedCheckboxes = tickedCheckboxes.filter(item => !collectionFieldsIds.includes(item));
    setTickedCheckboxes(updatedTickedCheckboxes);
  }
  console.log(tickedCheckboxes)
  const updatedLocalStorageData = { collectionData, tickedCheckboxes };
  const allCollectionCheckboxesSelected = await updatedCollectionData.every((collection) =>{
  collection.collectionFields.every((field) => field.isChecked)});
  const checkboxChangeEvent = new CustomEvent('checkboxChange', {
    detail: allCollectionCheckboxesSelected,
  });
  document.dispatchEvent(checkboxChangeEvent);
  await saveData('collectionDataState', JSON.stringify(updatedLocalStorageData));

};
if(collectionData && collectionData.length >0 && !optimizationInProgress){
  const collectionEvent = new Event('collectionData');
  document.dispatchEvent(collectionEvent);
}
  const savedImageInBytes = results.originalImagesSize - results.optimizedImagesSize;
  const savedImage = (savedImageInBytes / 1024).toFixed(2)+' KB';
  if(savedImage>10000){
    savedImage = (savedImage/1024).toFixed(2)+' MB'
  }
return (
    <div className="collections-data-top">
      {message.type == 'error'? message.msg=='Unable to find collections in the site. Make sure the site has CMS collections.' && collectionData.length == 0 ? (<div className='no-collection'>No Collection with images found in this site. If you think this is a mistake. <a href="https://www.trypixie.io/contact-us"> Reach out to us.</a></div>):(<div className='no-collection'> {message.msg} </div>) :( 
      <div>
      <div className='search-div'>
      <SearchBar searchQuery={searchQuery} handleSearch={handleSearch} backgroundColor={'#F6F6F6'} width={'58.59vw'} /> 
      </div>
      <div className=' circular-loader'> 
        {message.type == 'info' && collectionData.length == 0 && (
          <CircularProgressIndicator value={null} className='check-box-btn' style={{color:"black", marginTop:'5vh'}}/>
        )}
        </div>
      <div className='border'></div>  
      {filteredCollectionData.map(({ collectionDetails, collectionFields }) => (
        <div key={collectionDetails['id']} className="collection-data">
          <div className="collection-header">
          <div className="collection-name-row">
          <FormControlLabel
              // className='collection-name'
              control={
                <Checkbox
                  icon={<img className='check-box-btn' src={CheckEmpty} alt="Check icon" />}
                  checked={
                    collectionFields.every(field => field.isChecked) &&
                    collectionFields.length > 0
                  }
                  onChange={(event) =>
                    handleCollectionCheckboxChange(
                      collectionDetails.id,
                      event.target.checked
                    )
                  }
                  checkedIcon={<img className='check-box-btn' src={CheckChange} alt="Check icon" />}
                />
              }
              label={<div className='collection-name-txt'>{collectionDetails.displayName}</div>}
            />
            </div>

            <button className="expand-button" onClick={() => setShow(prevState => ({ ...prevState, [collectionDetails['id']]: !prevState[collectionDetails['id']] }))}>
              {show[collectionDetails['id']] ? (
                <img className="expand-arrow" src={UpArrow} alt="Up Arrow" style={{ transform: 'rotate(180deg)' }} />
                ) : (
                  <img className="expand-arrow" src={UpArrow} alt="Up Arrow" />
                  )}
            </button>
            </div>
          <div className="fields">
            {collectionFields.map((field) => (
              <div key={field.id} className="single-collection row">
                {!show[collectionDetails['id']] && 
                <div className='each-field'>
                <div className="multi">
                  
                  <FormControlLabel
                    className='checkbox-label'
                    control={optimizationInProgress ?<div className="icon-status">
                    {jobsInfo[collectionDetails.displayName]?.map(({ collectionFieldData, state, progress }) => (
                      // const jobMatchesField = collectionFieldData.id === field.id;
                      collectionFieldData.map(fieldData => (
                      <div key={fieldData.id}>
                        {fieldData.id === field.id && (
                          IconByStatus(state, field, progress)
                        )}
                      </div>
                    ))

                  ))}
                    {!fieldsId.includes(field.id) &&(
                    <Checkbox id={field.id} icon={<img className='check-box-btn' src={CheckEmpty} alt="Check icon" />}
                    checked={field.isChecked} onChange={handleCheckBoxChange}
                     checkedIcon={<img className='check-box-btn' src={CheckChange} alt="Check icon" />}
                    name="field.name"/>)}
                  </div>:<Checkbox id={field.id} icon={<img className='check-box-btn' src={CheckEmpty} alt="Check icon" />}
                    checked={field.isChecked} onChange={handleCheckBoxChange}
                     checkedIcon={<img className='check-box-btn' src={CheckChange} alt="Check icon" />}
                    name="field.name"/>}
                    label={<div className='field-name-txt'>{field.displayName}</div>}
                  />

                  {/*{
                    field.isAutoOptimized &&
                    <span>auto-optimized field</span>
                  }*/}
                  </div>
                    
                  {
                    field.isChecked &&
                    <div className='selector-class'>
                      <select value={field.selectValue} onChange={handleOptionChange}>
                      {
                        caseObj.map(echOpt => {
                          const parts = echOpt.optionName.split(':');
                          
                          if (parts.length === 2) {
                            const dataToDisplay = parts[1].trim();
                            
                            return (
                              <option key={echOpt.id} data-field={field.id} value={echOpt.valueName}>
                                {dataToDisplay}
                              </option>
                            );
                          } else {
                            return (
                              <option key={echOpt.id} data-field={field.id} value={echOpt.valueName}>
                                {echOpt.optionName}
                              </option>
                            );
                          }
                        })
                      }
                      </select>
                      {/*<FormControlLabel
                        control={<Checkbox id={field.id} icon={<CheckBoxOutlineBlank/>}
                                           onChange={handleAutoOptimizeCheckBoxChange}
                                           checked={field.isAutoOptimize}
                                           checkedIcon={<CheckBox style={{ color: '#0e172c' }}/>}/>}
                        label="auto-optimize">
                      </FormControlLabel>*/}
                    </div>
                  }
                  </div>
                }
                {/*{
                  field.isAutoOptimized &&
                  <span className="disable-icon" onClick={() => {
                    disableAutoOptimization(field.id);
                  }}>
                      <HighlightOffIcon/>
                  </span>
                }*/}
              </div>
            ))}
          </div>
          <div className='border'></div>
        </div>
        
      ))}

{tickedCheckboxes && (tickedCheckboxes.length > 0 || jobsInLs === true)&& (
      optimizationInProgress ? (
        (optimizedStatus ? (
          <BottomPopup
            text={`${type === 'convert' ? "Converted" : "Optimized"} Successfully!`}
            subtext={`(Saved ${savedImage} on ${type === 'convert' ? 'converting' : 'optimising'} ${results.totalImagesOptimized} images)`}
            buttonLabel={`${type === 'convert' ? "Convert" : "Optimize"} More`}
            showButton={true}
            link={true}
            type={type}
          />
        ) : (
          <BottomPopup showProgress nums={progress} type={type} />
        ))
      ) : (
        showPricingBtn ? <BottomPopup
        showButton={true}
        buttonLabel={'Pricing'}
        text={`You need a active subscription to ${type === 'convert' ? 'convert' : 'optimize'}`}
        onClose={() => setTickedCheckboxes([])}
        onButtonClick={() => {
          history.push('/pixie/pricing');
        }}
        type={type}
      /> :
        <BottomPopup
          showButton={true}
          buttonLabel={`${type === 'convert' ? 'Convert' : 'Optimize'} Now`}
          text={`${tickedCheckboxes.length} out of ${totalFieldsInCollection} Fields Selected to ${type === 'convert' ? 'Convert' : 'Optimize'}`}
          onClose={() => setTickedCheckboxes([])}
          onButtonClick={handleClick}
          type={type}
        />
      )
    )}
    
</div>)
      }
      {isModalOpen && (
        <Modal>
          <MultiOptionBox toggleModal={closeOpenModal} />
        </Modal>
      )}
    </div>
  );
};

export default CollectionFieldsWithOptions;
